import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DialogAction } from '@lc/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export class PromptDialogData {
  constructor(readonly title: string, readonly message: string, readonly primaryAction: DialogAction, readonly secondaryActions?: DialogAction[]) {}
}

@Component({
  selector: 'lc-prompt-dialog',
  templateUrl: './prompt-dialog.component.html',
  styleUrls: ['./prompt-dialog.component.scss'],
  standalone: false,
})
export class PromptDialogComponent {
  public message: SafeHtml;

  private readonly _close = new Subject<DialogAction>();
  public readonly close = this._close.asObservable();

  constructor(private sanitizer: DomSanitizer, @Inject(MAT_DIALOG_DATA) readonly data: PromptDialogData) {
    this.message = this.sanitizer.bypassSecurityTrustHtml(data.message);
  }

  onActionClicked(action: DialogAction) {
    this._close.next(action);
  }
}
