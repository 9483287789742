import { Component, Inject, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FieldVersionService, FieldVersionsData, FieldVersionsRes, PromptDialogService, IDialogOptions,
} from '@lc/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lc-field-version',
  templateUrl: './field-version.component.html',
  styleUrls: ['./field-version.component.scss'],
  standalone: false,
})
export class FieldVersionComponent {
  displayedColumns: string[] = ['modifiedTime', 'userFullName', 'key', 'value'];
  readonly fieldVersions$: Observable<any>;

  constructor(
    private fieldVersionService: FieldVersionService,
    @Optional() public dialogRef: MatDialogRef<FieldVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FieldVersionsData,
  ) {
    this.fieldVersions$ = this.fieldVersionService.create$(data).pipe(
      map((returnVersions) => returnVersions || [new FieldVersionsRes()]),
    );
  }

  onClose() {
    this.dialogRef?.close(undefined);
  }

  static async open(service: PromptDialogService, data?: FieldVersionsData, options?: IDialogOptions): Promise<any> {
    const defaultOptions: IDialogOptions = {
      width: '900px', disableClose: false, panelClass: 'p-0', autoFocus: false,
    };
    const opts: IDialogOptions = Object.assign(defaultOptions, options);
    return await service.openComponent(FieldVersionComponent, data, opts);
  }
}
