<div
  #photo
  class="photo h-100 fx-column x-center y-center"
  [class.canmove]="viewModel.canReorder"
  (mouseenter)="isFocused = true"
  (mouseleave)="isFocused = false"
  [class.disabled]="viewModel.disabled"
  [class.selected]="viewModel.showSelected && viewModel.inUseIndex > -1"
  [matTooltip]="viewModel.tooltip"
  [matTooltipDisabled]="!viewModel.tooltip"
  matTooltipPosition="above"
>
  <!-- Main card Image -->
  <div [attr.title]="title"
       class="photo-card border"
       [ngClass]="{
       'highlight-selected': viewModel.isSelected,
       'border-color-primary': viewModel.isSelected,
       'border-round': roundImg,
       'border-color-warn': viewModel.isRequired && viewModel.isDefault(),
       'text-warn': viewModel.isRequired && viewModel.isDefault()
     }">


  <div *ngIf="!viewModel.isDefault()"
         class="card-img">
      <div class="card-img-cover">
          <img
            #img
            [id]="'img-' + viewModel.photo?.order"
            [size]="thumbnailSize"
            [fallbackSize]="fallbackThumbnailSize"
            [ngClass]="{'opacity-25': viewModel.canHide && viewModel?.photo?.hidden}"
            [lcPresignedPhoto]="viewModel.photo"
            (load)="onImageLoaded(viewModel, $event)"
          >
      </div>
    </div>

    <div [id]="'default-img-' + viewModel.photo?.order" *ngIf="viewModel.isDefault()" class="default-img fx-column x-center y-center">
      <span class="material-icons-outlined">add_photo_alternate</span>
      <div class="font-weight-normal">Drop your image here</div>
    </div>

    <lc-spinner *ngIf="viewModel?.loading?.isLoading" [spinnerText]="viewModel?.loading?.loadingText" [showOverlay]="true"></lc-spinner>

    <!-- Overlay -->
    <div class="overlay">


      <!--Favorites Button-->
      <a *ngIf="viewModel.canFavorite || (viewModel.showFavorite && viewModel.photo.favorite)" class="favorite-button" [class.clickable]="viewModel.canFavorite" [class.small]="viewModel.useSmallIcons" (click)="onToggleFavorite(viewModel); $event.stopPropagation();">
        <img *ngIf="viewModel.photo.favorite" class="favorite-icon favorite" src="/assets/images/fav_on_icon.svg">
        <img *ngIf="!viewModel.photo.favorite" class="favorite-icon not-favorite" src="/assets/images/fav_off_icon.svg">
      </a>

      <!-- Photo Order Spinner -->
      <div *ngIf="viewModel.canReorder || viewModel.showOrder" class="photo-order">
        <span [hidden]="viewModel.isEditNumber" class="text-bottom order-number photo-index" [class.bg-warn]="viewModel.isDefault()" (click)="onEditOrder(viewModel, true); $event.stopPropagation();">{{viewModel.photo.order}}</span>
        <input #order [hidden]="!viewModel.isEditNumber" type="number" min="1" [max]="totalCount"
          (blur)="onReorder(viewModel, order.value)" (click)="$event.stopPropagation()" [value]="viewModel.photo.order" class="form-control-number-sm order-input" (keyup.enter)="onKeyEnter($event)"/>
      </div>

      <!-- Checkbox Photo -->
      <lc-checkbox *ngIf="viewModel.canSelect"
                   id="controlPrefix + '-checkbox'"
                   class="select-img white"
                   name="select_photos"
                   [(ngModel)]="viewModel.isSelected"
                   (click)="$event.stopPropagation()"
                   (toggled)="onSelected(viewModel)">
      </lc-checkbox>

      <!-- Checkbox for hiding a website gallery photo -->
      <button *ngIf="viewModel.canHide" class="top-left archive-toggle xs" mat-mini-fab color="default" (mouseenter)="showHover=true" (mouseleave)="showHover=false" (click)="onHideClicked($event, viewModel)" >
        <mat-icon class="material-icons-outlined">{{(viewModel?.photo?.hidden && !showHover) || (!viewModel?.photo?.hidden && showHover) ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>

      <!-- Download Button -->
      @if((isFocused || iconsPersist) && viewModel.canDownload) {
        <button mat-mini-fab class="download-button xs"  color="default" (click)="onDownload(viewModel); $event.stopPropagation();">
          <mat-icon  class="material-icons-outlined">download</mat-icon>
        </button>
        <!-- <span class="download-button icon-circle fa fa-download clickable"></span> -->
      }
      <!-- Delete Button (Placement = Default) -->
      @if((isFocused || iconsPersist) && viewModel.canDelete && viewModel.iconPlacement !== 'center') {
      <button mat-mini-fab class="delete-button xs" color="default">
        <mat-icon class="material-icons-outlined">delete</mat-icon>
      </button>
      }

      <!-- Edit/Delete Button (Placement = Center) -->
      @if((isFocused || iconsPersist) && viewModel.iconPlacement === 'center') {
        <div [ngClass]="viewModel.canEdit ? 'delete-edit-buttons-top-right': 'delete-only-buttons-top-right'" class="fx-row fx-gap-xs">
          <button *ngIf="viewModel.canEdit" class="edit-button xs" mat-mini-fab color="default" (click)="onEdit(viewModel); $event.stopPropagation();" >
            <mat-icon class="material-icons-outlined">crop</mat-icon>
          </button>
          <button *ngIf="viewModel.canDelete"  class="xs" mat-mini-fab color="default" (click)="onDelete(viewModel); $event.stopPropagation();" >
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      }
    </div>
  </div>
</div>
