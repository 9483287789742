import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProfileInfo } from '@lc/core';
import { BulkAssignDialogComponent, BulkAssignDialogData } from './bulk-assign-dialog.component';

/**
 * Handles the creation of the BulkAssign Dialog component by requiring proper parameter construction
 * and eliminates the need to inject the modal into the components directly. This allows
 * the ability to change implementation if we wanted to switch to a different dialog component (i.e. - Angular Material)
 */
@Injectable()
export class BulkAssignDialogService {
  constructor(public dialog: MatDialog) { }

  /**
   * Opens the BulkAssign dialog component with the required parameters
   * @param title The title of the dialog
   * @param subheader The subtitle of the dialog
   */
  openBulkAssign(title: string, subtitle: string): Promise<ProfileInfo> {
    const dialogRef = this.dialog.open<BulkAssignDialogComponent, any, ProfileInfo>(BulkAssignDialogComponent, {
      width: '400px',
      data: new BulkAssignDialogData(title, subtitle),
    });

    const component: BulkAssignDialogComponent = dialogRef.componentInstance;

    component.close.subscribe((action) => dialogRef.close(action), (error) => { throw new Error(error); });
    return dialogRef.afterClosed().toPromise();
  }
}
