import { EventEmitter, Injectable, Output } from '@angular/core';
import { FeatureFlags, LaunchDarklyService, ListingPhoto, MarketingOrder } from '@lc/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { PhotoGalleryDialogComponent, PhotoGalleryDialogData } from './photo-gallery-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class PhotoGalleryDialogService {
  @Output() photosUpdated = new EventEmitter<ListingPhoto[]>();

  constructor(
    private modal: MatDialog,
    private launchDarklyService: LaunchDarklyService,
  ) { }

  /**
   * Opens the dialog component with the required parameters
   */
  async open(
    title: string,
    photos: ListingPhoto[],
    index?: number,
    marketingOrder?: MarketingOrder,
    canDelete?: boolean,
    canFavorite?: boolean,
    canDownload?: boolean,
  ) {
    const aiFeatureIsEnabled = await firstValueFrom(
      this.launchDarklyService.getFeature$(FeatureFlags.AI_IMAGE_TAGGING, false),
    );
    const dialogRef = this.modal.open(PhotoGalleryDialogComponent, {
      data: new PhotoGalleryDialogData(title, photos, index, marketingOrder, canDelete, canFavorite, canDownload),
      width: '90vw',
      maxWidth: aiFeatureIsEnabled ? '800px' : '1200px',
      height: '90vh',
      maxHeight: aiFeatureIsEnabled ? '875px' : '800px',
      panelClass: 'photo-gallery',
    });
    const component: PhotoGalleryDialogComponent = dialogRef.componentInstance;

    component.photosUpdated.subscribe((updatedPhotos: ListingPhoto[]) => { this.photosUpdated.emit(updatedPhotos); });

    component.close.subscribe((action) => dialogRef.close(action), (error) => { throw new Error(error); });
    return dialogRef.afterClosed().toPromise();
  }
}
