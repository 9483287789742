
<!--
  Container for the photo-gallery-dialog:
    (keydown) events:
      Handles scrolling with the dialog
-->
<lc-base-dialog id="photo-gallery-dialog" [title]="title" (actionClicked)="onClose()" (keydown.arrowleft)="prevSlide()" (keydown.arrowright)="nextSlide()" >

  <!--Modal Body-->
  <ng-template #content>
    <div class="fx-column fx-grow fx-gap-xs" *ngIf="photos">
      <div class="photo-gallery fx-row fx-gap-xs y-center x-space-between" style="min-height: 365px;">
        <!-- Left Scroll Button -->
        <button class="back fx-0-0" mat-icon-button (click)="prevSlide()">
          <mat-icon>chevron_left</mat-icon>
        </button>

        <!-- Carousel Container-->
        <div [@slideAnimation]="currentIndex" class="h-100 w-100">
          <ng-template ngFor let-vm [ngForOf]="photoViewModels" let-i="index">
            <div *ngIf="isCurrentSlideIndex(i)" (swiperight)="prevSlide()" (swipeleft)="nextSlide()" class="image-wrapper">
              <div class="image-container">
                <lc-photo
                  [roundImg]="false"
                  [viewModel]="vm"
                  [thumbnailSize]="thumbnailSize"
                  [deleteStyle]="'CAROUSEL'"
                  (delete)="handleDelete($event)"
                  (toggleFavorites)="handleToggleFavorites($event)"
                  [iconsPersist]="true"
                >
                </lc-photo>
              </div>
            </div>
          </ng-template>
        </div>

        <!-- Right Scroll Button -->
        <button class="forward fx-0-0" mat-icon-button (click)="nextSlide()">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>

      <div class="fx-row x-center">
        <span class="font-weight-bold photo-index">Photo {{selectedIndex + 1}} of {{photos.length}}</span>
      </div>

      <div *ngIf="aiFeatureIsEnabled$ | async">
        <div class="fx-column fx-gap-xs">
          <lc-textarea
            label="Caption"
            placeholder="i.e., Charming home with vaulted ceilings and..."
            [formControl]="photoForms[selectedIndex].getControl('caption')"
            class=""
            rows="3">
          </lc-textarea>
        </div>

        <div class="fx-column fx-gap-xs">
          <lc-chips
            label="Key Property Features"
            hint="Add or remove property features from the photo that you would like to add to this property."
            size="md" [formControl]="photoForms[selectedIndex].getControl('tags')" [allowCustomInput]="true"
            placeholder="i.e., pool, patio, stainless steel appliances, etc.">
          </lc-chips>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #actions>
    <div *ngIf="aiFeatureIsEnabled$ | async" class="fx-row x-space-between py-md">
      <button id="property-description-cancel" type="button" style="margin: 0;" mat-stroked-button
              color="primary" (click)="onClose()">
        Back
      </button>
      <button id="property-description-submit m-0" type="submit" style="margin: 0;" mat-raised-button
              color="primary" (click)="onSave()" [disabled]="disableSave">
        Save
      </button>
    </div>
  </ng-template>
</lc-base-dialog>
