import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { NotesDialogComponent, NotesDialogData } from './notes-dialog.component';

/**
 * Class used to instantiate a proper configuration object used as a parameter to `NotesDialogService.openNotes()`
 *
 * Example usage:
 *
 *
 *```
 * const service = new NotesDialogService();
 * const config = new NotesDialogServiceConfig({ ownerId: 'OWNERID', title: 'TITLE', subtitle: 'SUBTITLE' });
 * service.openNotes(config);
 *```
 *
 *
 */
export class NotesDialogServiceConfig {
  // The ID of the entity we are requesting notes for
  ownerId!: string;
  // The title of the Dialog
  title?: string;
  // The subtitle of the Dialog
  subtitle!: string;

  constructor(value?: Partial<NotesDialogServiceConfig>) {
    Object.assign(this, value);
  }
}

/**
 * Handles the creation of the Notes Dialog component by requiring proper parameter construction
 * and eliminates the need to inject the dialog into the components directly. This allows
 * the ability to change implementation if we wanted to switch to a different dialog component (i.e. - Angular Material)
 */
@Injectable()
export class NotesDialogService {
  isDialogOpen = false;
  constructor(private modal: MatDialog) { }

  /**
   * Opens the notes dialog component with the required parameters.
   *
   * If no title is provided in the parameter object, then the title will default to "Add Note". However, a value for `ownerId` and `subtitle` are required.
   *
   * Example 1:
   *
   * ```
   * openNotes({ title: 'TITLE', ownerId: 'ORDERID', subtitle: 'SUBTITLE' });
   * ```
   *
   * Example 2:
   * ```
   * const config = new NotesDialogServiceConfig({ ownerId: 'ORDERID', subtitle: 'SUBTITLE' });
   * openNotes(config);
   * ```
   *
   *
   */
  openNotes(config: NotesDialogServiceConfig) {
    const { ownerId, title, subtitle } = config;
    const dialogRef = this.modal.open<NotesDialogComponent, NotesDialogData, any>(NotesDialogComponent, {
      data: new NotesDialogData(ownerId, title ?? 'Add Note', subtitle), width: '500px', maxWidth: '90vw', maxHeight: '90vh',
    });
    this.isDialogOpen = true;
    const component: NotesDialogComponent = dialogRef.componentInstance;

    component.close.subscribe((action) => { this.isDialogOpen = false; return dialogRef.close(action); }, (error) => { throw new Error(error); });
    dialogRef.afterClosed().pipe(finalize(() => this.isDialogOpen = false)).toPromise();
    return dialogRef;
  }

  /**
   * If the dialog is currently open, returns `true`. Otherwise, returns `false`.
   */
  isNotesOpen() {
    return this.isDialogOpen;
  }
}
